import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button, Modal, Select, Avatar, TimePicker } from "antd";
import { FiEdit2 } from "react-icons/fi";
import { RxCalendar } from "react-icons/rx";
import { FaArrowLeft, FaRegChartBar, FaArrowRight } from "react-icons/fa6";
import { MdOutlineContacts, MdMedicalServices, MdPhotoAlbum, MdLockReset } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScheduleList from "./ScheduleList";
import { assignEmpToClient, resetDevice } from "../redux/actions/employeesAction";
import moment from "moment";
import ImageDS from "./utils/ImageDS";

const EmployeeData = () => {
  const { employees, selectedEmployee } = useSelector((state) => state.employees);
  const user = useSelector((state) => state.auth.user);
  const { clients } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [disabledDevices, setDisabledDevices] = useState({});
  const [modalData, setModalData] = useState(null);
  const [dateAndTime, setDateAndTime] = useState([]);
  const [curDateTime, setCurDateTime] = useState({
    days: [],
    startTime: "",
    endTime: "",
    timeValue: null,
    isRecurring: true,
    type: "session",
  });
  const [selectedClient, setSelectedClient] = useState();
  const handleAssign = (record) => {
    setModalData(record);
    setModalOpen(true);
  };
  const saveAssignment = async () => {
    // console.log(modalData);
    const res = await dispatch(
      await assignEmpToClient({
        clientId: selectedClient,
        employeeId: modalData.id,
        timeSheet: dateAndTime,
      })
    );
    setModalOpen(false);
  };

  const handleResetDevice = async (record) => {
    try {
      // Call the resetDevice API
      const isSuccess = await resetDevice(record.id)();

      if (isSuccess) {
        // Update the UI state to disable the button for this record
        setDisabledDevices((prevState) => ({
          ...prevState,
          [record.id]: true,
        }));
        console.log("Device reset successfully.");
      } else {
        console.log("Failed to reset the device.");
      }
    } catch (error) {
      console.error("Unexpected error during reset:", error);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      render: (_, record) => (
        <div className="flex items-center">
          {/* Display the employee image */}
          <ImageDS imageKey={record.imageKey} width={40} height={40} borderRadius={20} />
          {/* Display the employee name */}
          <span className="ml-4">{record.fullName}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Emp No",
      dataIndex: "employeeNumber",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <div className="flex items-center justify-start gap-4 text-[#444454] text-[24px]">
          <Tooltip title="View Clients and timesheets">
            <div
              onClick={() => handleViewClients(record)}
              className="flex px-4 py-2.5 justify-center cursor-pointer items-center bg-[#0a1d311a]  rounded-[8px]"
            >
              <MdPhotoAlbum className="text-[#3795F7] cursor-pointer mr-1 text-[15px]" />
              {/* <span className="text-[#3795F7] font-[600] text-[13px]">Sessions</span> */}
            </div>
          </Tooltip>

          <Tooltip title="View Session">
            <div
              onClick={() => handleViewSession(record)}
              className="flex px-4 py-2.5 justify-center cursor-pointer items-center bg-[#0a1d311a]  rounded-[8px]"
            >
              <EyeOutlined className="text-[#3795F7] cursor-pointer mr-1 text-[15px]" />
              {/* <span className="text-[#3795F7] font-[600] text-[13px]">Sessions</span> */}
            </div>
          </Tooltip>

          <Tooltip title="View Calendar">
            <div
              onClick={() => handleCalendar(record)}
              className="flex px-4 py-2.5 justify-center cursor-pointer items-center bg-[#0a1d311a]  rounded-[8px]"
            >
              <RxCalendar className="text-[#3795F7] cursor-pointer mr-1 text-[15px]" />
              {/* <span className="text-[#3795F7] font-[600] text-[13px]">Calendar</span> */}
            </div>
          </Tooltip>
          {/* Reset Device Icon */}
          <Tooltip
            title={
              record.user?.deviceId
                ? disabledDevices[record.id]
                  ? "Device reset already"
                  : "Reset Device"
                : "Device ID does not exist for this employee"
            }
          >
            <div
              onClick={() =>
                record.user?.deviceId && !disabledDevices[record.id]
                  ? handleResetDevice(record)
                  : null
              }
              className={`flex px-4 py-2 justify-center items-center rounded-[8px] ${
                record.user?.deviceId && !disabledDevices[record.id]
                  ? "bg-[#3795F71A] cursor-pointer"
                  : "bg-[#0a1d311a] cursor-not-allowed"
              }`}
            >
              <MdLockReset
                className={`text-[20px] ${
                  record.user?.deviceId && !disabledDevices[record.id]
                    ? "text-[#3795F7] cursor-pointer"
                    : "text-gray-500"
                }`}
              />
            </div>
          </Tooltip>

          {user?.role == "admin" && (
            <>
              <Tooltip title="Edit employee Info">
                <div
                  onClick={() => handleEdit(record)}
                  className="flex px-4 py-2.5 justify-center cursor-pointer items-center bg-[#3795F71A]  rounded-[8px]"
                >
                  <FiEdit2
                    className="text-[#3795F7] cursor-pointer mr-1 text-[15px]"
                    onClick={() => handleEdit(record)}
                  />
                  {/* <span className="text-[#3795F7] font-[600] text-[13px]">Edit</span> */}
                </div>
              </Tooltip>
              <Tooltip title="Assign to an client">
                <div
                  onClick={() => handleAssign(record)}
                  className="flex px-4 py-2 justify-center cursor-pointer items-center bg-[#3795F71A]  rounded-[8px]"
                >
                  <span className="text-[#3795F7] font-[600] text-[13px]">Assign</span>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      ),
    },
  ];
  const handleEdit = (record) => {
    console.log(record);
    dispatch({
      type: "SET_SELECTED_EMPLOYEE",
      payload: record,
    });
    navigate(`/edit-employee/${record.uuid}`);
  };
  const handleViewSession = (record) => {
    dispatch({
      type: "SET_SELECTED_EMPLOYEE",
      payload: record,
    });
    navigate(`/employee/sessions/${record.uuid}`);
  };
  const handleCalendar = (record) => {
    dispatch({
      type: "SET_SELECTED_EMPLOYEE",
      payload: record,
    });
    navigate("/employee/calendar");
  };
  const handleViewClients = (record) => {
    console.log(record);
    dispatch({
      type: "SET_SELECTED_EMPLOYEE",
      payload: record,
    });
    setModalOpen2(true);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSearch = (value) => {
    setSearchText(value);
  };

  const filteredData = employees.filter(
    (item) =>
      item.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email.toLowerCase().includes(searchText.toLowerCase()) ||
      item.employeeNumber.toLowerCase().includes(searchText.toLowerCase())
    // ||
    // item.number.includes(searchText)
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const paginationConfig = {
    showSizeChanger: false,
    position: ["bottomCenter"],
    prevIcon: (
      <Button
        type="text"
        size="large"
        className="flex items-center justify-center gap-2 w-[115px] text-[#444454] border-gray-200 border rounded-[8px] absolute bottom-0 left-0 ml-4 "
      >
        <FaArrowLeft />
        <span className="text-[14px]">Previous</span>
      </Button>
    ),
    nextIcon: (
      <Button
        type="text"
        size="large"
        className="flex items-center justify-center gap-2 w-[115px] text-[#444454] border border-gray-200 rounded-[8px] absolute bottom-0 right-0 mr-4 "
      >
        <span className="text-[14px]">Next</span>
        <FaArrowRight />
      </Button>
    ),
  };
  useEffect(() => {
    console.log(dateAndTime);
  }, [dateAndTime]);

  return (
    <div>
      {selectedEmployee && (
        <Modal
          width={"60rem"}
          centered
          open={modalOpen2}
          onCancel={() => setModalOpen2(false)}
          onOk={() => setModalOpen2(false)}
        >
          <div className="w-full justify-start items-center">
            <p className="text-lg mb-4 font-semibold">
              {selectedEmployee?.fullName} - {selectedEmployee?.email}
            </p>

            <div className="flex flex-col justify-start items-center gap-2 w-full mx-6">
              {selectedEmployee?.employeeClient.map((ec) => (
                // <div className="shadow-md border w-fit mx-auto px-8 rounded-md py-4">
                <div className="w-full">
                  <div className="w-full flex flex-row gap-2 items-center">
                    <ImageDS
                      imageKey={ec.client.imageKey}
                      width={20} // Smaller size in dropdown
                      height={20} // Smaller size in dropdown
                      borderRadius={20}
                    />
                    <p className="text-lg ">{ec.client.fullName}</p>
                    <p className="text-base text-gray-700">{ec.client.email}</p>
                    <p className="text-base text-gray-700 ">{ec.client.uci}</p>
                  </div>
                  <ScheduleList dateAndTime={ec.timeSheet} size={"base"} />
                </div>
              ))}
            </div>
          </div>
        </Modal>
      )}
      <Modal
        title="Assign an employee to client"
        width={"60rem"}
        okText={"Assign"}
        centered
        open={modalOpen}
        onOk={saveAssignment}
        onCancel={() => setModalOpen(false)}
      >
        <div className="w-full  grid grid-cols-2 justify-center items-center">
          <div className="mt-6">
            <div className="shadow-md border w-[400px] mx-auto px-10 rounded-md py-4">
              <div className="flex flex-col ">
                <ImageDS imageKey={modalData?.imageKey} width={40} height={40} borderRadius={20} />
                <p className="text-base ">{modalData?.fullName}</p>

                <p className="text-sm text-gray-700">{modalData?.email}</p>
                <p className="text-sm text-gray-700 my-1">{modalData?.number}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-center gap-2">
            <div>
              <h2 className="my-1 ml-1">Select Client:</h2>
              <Select
                showSearch
                placeholder="Select a Client"
                value={selectedClient}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.label.props.children[0].toLowerCase().includes(input.toLowerCase())
                }
                className="w-[400px]"
                onChange={(e) => setSelectedClient(e)}
                options={clients.map((employee) => ({
                  value: employee.id,
                  label: <>{employee.fullName} <span className="text-gray-500 text-xs">{employee.email}</span></>,
                }))}
              />
            </div>
            <ScheduleList dateAndTime={dateAndTime} />
            <div>
              <h2 className="my-1 ml-1">Select Days & Time:</h2>
              <Select
                mode="tags"
                className="w-[400px]"
                placeholder="Please select days"
                value={curDateTime.days}
                onChange={(e) => setCurDateTime({ ...curDateTime, days: e })}
                options={[
                  { value: "monday" },
                  { value: "tuesday" },
                  { value: "wednesday" },
                  { value: "thursday" },
                  { value: "friday" },
                  { value: "saturday" },
                  { value: "sunday" },
                ]}
              />
            </div>
            <TimePicker.RangePicker
              className="w-[400px]"
              use12Hours
              format="h:mm a"
              value={curDateTime.timeValue}
              onChange={(e) =>
                setCurDateTime({
                  ...curDateTime,
                  startTime: e[0].$d,
                  endTime: e[1].$d,
                  timeValue: e,
                })
              }
            />
            <div className="flex w-[400px] mx-auto items-center justify-between">
              <div className="flex justify-start items-center">
                {/*     <input
                  type="checkbox"
                  checked={curDateTime.isRecurring}
                  onChange={(e) =>
                    setCurDateTime({
                      ...curDateTime,
                      isRecurring: e.target.checked,
                    })
                  }
                />
                <label htmlFor="" className="ml-2 text-gray-700">
                  Repeats every week
                </label>*/}
              </div>
              <button
                type="button"
                onClick={() => {
                  setDateAndTime([...dateAndTime, curDateTime]);
                  setCurDateTime({
                    days: [],
                    startTime: "",
                    endTime: "",
                    timeValue: null,
                    isRecurring: true,
                  });
                }}
                className={`btn-primary ml-auto mr-0 ${
                  (!curDateTime?.days.length > 0 ||
                    !curDateTime.startTime ||
                    !curDateTime.endTime) &&
                  "opacity-60"
                }`}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="relative flex flex-col w-full gap-2 p-4 md:w-1/2">
        <div className="relative flex w-full ">
          <IoSearch className="text-[18px] text-[#696976] absolute left-2 top-3" />
          <input
            className="pl-8 rounded-[8px] w-full p-2 border outline-none"
            placeholder="Search Employee"
            value={searchText} // Controlled input value
            onChange={(e) => onSearch(e.target.value)} // Handle input change
          />
        </div>
      </div>

      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={filteredData}
        style={{ color: "#444454" }}
        pagination={paginationConfig}
      />
    </div>
  );
};

export default EmployeeData;
