import React, { useEffect, useState } from "react";
import { getAssetLink } from "../../redux/actions/assetsAction"; // Assuming this action is already set up
import { useDispatch } from "react-redux";

const ImageDS = ({
  imageKey,
  width,
  height,
  borderRadius = 20,
  placeholderImage = require("../../assets/random_user.png"),
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchImageUrl = async () => {
      // Validate the imageKey
      if (!imageKey || imageKey.trim() === "") {
        setImageUrl(placeholderImage); // Fallback to placeholder image
        return;
      }

      try {
        const url = await dispatch(getAssetLink(imageKey));
        setImageUrl(url);
      } catch (error) {
        console.error("Error fetching image URL:", error);
        setImageUrl(placeholderImage); // Fallback to placeholder image in case of an error
      }
    };

    fetchImageUrl();
  }, [imageKey, dispatch, placeholderImage]);

  return (
    <div>
      <img
        src={imageUrl ? imageUrl : placeholderImage}
        alt="User"
        style={{
          width: `${width}px`,
          height: `${height}px`,
          borderRadius: `${borderRadius}px`,
          objectFit: "cover",
        }}
      />
    </div>
  );
};

export default ImageDS;
