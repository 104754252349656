import React, { useEffect } from "react";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import ClientData from "../../components/ClientData";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients } from "../../redux/actions/clientsAction";
const Client = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/add-client");
  };
  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  return (
    <>
      <SearchBar />
      <div className="flex items-center gap-3 px-6 py-2 ">
        <div className="flex items-center gap-2 cursor-pointer text-sm text-[#696976]">
          <FaHome />
          <span className="cursor-pointer" onClick={() => navigate("/")}>
            Dashboard{" "}
          </span>
        </div>
        <span className="text-sm text-[#696976]">/ </span>

        <span className="cursor-pointer text-sm font-semibold text-[#444454]">
          Client{" "}
        </span>
      </div>

      <h1 className="px-6 py-2 text-[#161D20] font-bold text-[24px]">
        Client{" "}
      </h1>

      <div className="px-6 py-2">
        <div className="border rounded-lg ">
          <div className="p-4 px-6 border-b ">
            <div className="flex flex-wrap items-center justify-between gap-4 ">
              <h2 className="text-[20px] text-[#101828] font-[600]">
                All Client
              </h2>
              {user?.role == "admin" && (
                <button
                  className="flex items-center text-sm font-[500] text-[#3795F7] gap-2 p-2 border border-[#3795F7] rounded-[8px]"
                  onClick={handleClick}
                >
                  <GoPlus className="text-xl" />
                  <span className="">Add Client</span>
                </button>
              )}
            </div>
          </div>

          <div className="">
            <ClientData />
          </div>
        </div>
      </div>
    </>
  );
};

export default Client;
