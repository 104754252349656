import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import axios from "axios";
import { message } from "antd";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const createClient = (emp) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post(
      "/clients/create",
      {
        data: emp,
      }
    );
    if (status === 200) {
      dispatch({
        type: "CREATE_CLIENT",
        payload: data.client,
      });
      message.success(data.message);
      return data.client;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const updateClient = (emp) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post(
      "/clients/update",
      {
        data: emp,
      }
    );
    if (status === 200) {
      dispatch({
        type: "UPDATE_CLIENT",
        payload: data.client,
      });
      message.success(data.message);
      return true;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getAllClients = () => async (dispatch) => {
  try {
    // console.log("request made")
    const { data, status } = await authenticatedInstance.get(
      "/clients/get-all"
    );
    // console.log("request made");

    if (status === 200) {
      dispatch({
        type: "SET_ALL_CLIENTS",
        payload: data.clients,
      });
      // message.success(data.message);
      return true;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getClientCalendar = (clientId) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get(
      `/clients/get-calendar?clientId=${clientId}`
    );
    if (status === 200) {
      let evnt = [];

      for (const item of data.events) {
        evnt.push({
          id: item.id,
          title: item?.title,
          description: item?.description,
          employeeId: item.employeeId,
          start: new Date(item.start),
          end: new Date(item.end),
          type: item.type,
        });
      }
      // console.log(evnt);
      dispatch({
        type: "SET_SELECTED_CLIENT_CALENDAR",
        payload: evnt,
      });
      // message.success(data.message);
      return true;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const updateASummary = (summary) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post(
      "/clients/update-summary",
      {
        data: summary,
        id: summary.id,
      }
    );
    if (status === 200) {
      message.success(data.message);
      return data.summary;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const addASummary = (summary, client) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post(
      "/clients/add-summary",
      {
        data: summary,
        client,
      }
    );
    if (status === 200) {
      message.success(data.message);
      return data.summary;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const uploadSirDoc = (options) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post(
      "/clients/sir/create",
      options
    );
    if (status === 200) {
      message.success(data?.message);
      return data.sir;
    }
    return data;
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getAllSIRs = (clientId) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get(
      `/clients/sir/get-all/${clientId}`
    );
    if (status === 200) {
      return data.sirs;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const uploadMiscDoc = (options, model) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post(
      `/clients/${model}/create`,
      options
    );
    if (status === 200) {
      message.success(data?.message);
      return data.data;
    }
    return data;
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getAllMisc = (clientId, model) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get(
      `/clients/${model}/get-all/${clientId}`
    );
    if (status === 200) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
