import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button, Modal, Select, Avatar, TimePicker } from "antd";
import { FiEdit2 } from "react-icons/fi";
import { RxCalendar } from "react-icons/rx";
import { FaArrowLeft, FaRegChartBar, FaArrowRight } from "react-icons/fa6";
import { MdOutlineContacts, MdMedicalServices } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScheduleList from "./ScheduleList";
import { assignEmpToClient } from "../redux/actions/employeesAction";
import ImageDS from "./utils/ImageDS";

const ClientData = () => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      render: (_, record) => (
        <div className="flex items-center">
          {/* Display the client image */}
          <ImageDS imageKey={record.imageKey} width={40} height={40} borderRadius={20} />
          {/* Display the client name */}
          <span className="ml-4">{record.fullName}</span>
        </div>
      ),
    },
    {
      title: "UCI",
      dataIndex: "uci",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <div className="flex items-center justify-start gap-4 text-[#444454] text-[24px]">
          <Tooltip title="View Face Sheet">
            <div
              onClick={() => handleClick(record, "face-sheet")}
              className="flex px-4 py-2.5 justify-center cursor-pointer items-center bg-[#0a1d311a]  rounded-[8px]"
            >
              {/* <MdOutlineContacts className="text-[#3795F7] cursor-pointer mr-1 text-[15px]" /> */}
              <EyeOutlined className="text-[#3795F7] cursor-pointer mr-1 text-[15px]" />
            </div>
          </Tooltip>

          {user?.role == "admin" && (
            <>
              <Tooltip title="Edit Client Info">
                <div
                  onClick={() => handleClick(record, "edit")}
                  className="flex px-4 py-2.5 justify-center cursor-pointer items-center bg-[#3795F71A]  rounded-[8px]"
                >
                  <FiEdit2 className="text-[#3795F7] cursor-pointer mr-1 text-[15px]" />
                  {/* <span className="text-[#3795F7] font-[600] text-[13px]">Edit</span> */}
                </div>
              </Tooltip>
              <Tooltip title="Assign to an employee">
                <div
                  onClick={() => handleAssign(record)}
                  className="flex px-4 py-2 justify-center cursor-pointer items-center bg-[#3795F71A]  rounded-[8px]"
                >
                  <span className="text-[#3795F7] font-[600] text-[13px]">Assign</span>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      ),
    },
  ];
  const { clients } = useSelector((state) => state.clients);
  const { employees } = useSelector((state) => state.employees);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [dateAndTime, setDateAndTime] = useState([]);
  const [curDateTime, setCurDateTime] = useState({
    days: [],
    startTime: "",
    endTime: "",
    timeValue: null,
    isRecurring: true,
  });
  const [selectedEmployee, setSelectedEmployee] = useState();
  const handleAssign = (record) => {
    setModalData(record);
    setModalOpen(true);
  };
  const saveAssignment = async () => {
    // console.log(modalData);
    const res = await dispatch(
      await assignEmpToClient({
        employeeId: selectedEmployee,
        clientId: modalData.id,
        timeSheet: dateAndTime,
      })
    );
    setModalOpen(false);
  };

  const handleEdit = async (record) => {
    await dispatch({
      type: "SET_SELECTED_CLIENT",
      payload: record,
    });
    navigate(`/edit-client/${record.uuid}`);
    console.log("Edit clicked for record:", record);
  };
  const handleClick = (record, type) => {
    dispatch({
      type: "SET_SELECTED_CLIENT",
      payload: record,
    });
    navigate(`/client/${type}/${record.uuid}`);
  };
  const handleCalendar = (record) => {
    console.log("Calendar clicked for record:", record);
    dispatch({
      type: "SET_SELECTED_CLIENT",
      payload: record,
    });
    navigate("/client/calendar");
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSearch = (value) => {
    setSearchText(value);
  };

  const filteredData = clients.filter(
    (item) =>
      item.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
      item.uci.toLowerCase().includes(searchText.toLowerCase()) ||
      item.number.includes(searchText)
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const paginationConfig = {
    showSizeChanger: false,
    position: ["bottomCenter"],
    prevIcon: (
      <Button
        type="text"
        size="large"
        className="flex items-center justify-center gap-2 w-[115px] text-[#444454] border-gray-200 border rounded-[8px] absolute bottom-0 left-0 ml-4 "
      >
        <FaArrowLeft />
        <span className="text-[14px]">Previous</span>
      </Button>
    ),
    nextIcon: (
      <Button
        type="text"
        size="large"
        className="flex items-center justify-center gap-2 w-[115px] text-[#444454] border border-gray-200 rounded-[8px] absolute bottom-0 right-0 mr-4 "
      >
        <span className="text-[14px]">Next</span>
        <FaArrowRight />
      </Button>
    ),
  };

  const handleRowClick = (record) => {
    window.location.href = "/session-details";
    console.log("Row clicked for record:", record);
  };

  return (
    <div>
      <Modal
        title="Assign a client to employee"
        width={"60rem"}
        okText={"Assign"}
        centered
        open={modalOpen}
        onOk={saveAssignment}
        onCancel={() => setModalOpen(false)}
      >
        <div className="w-full  grid grid-cols-2 justify-center items-center">
          <div className="mt-6">
            <div className="shadow-md border w-[400px] mx-auto px-8 rounded-md py-4">
              <div className="flex flex-col ">
                <ImageDS imageKey={modalData?.imageKey} width={40} height={40} borderRadius={20} />
                <p className="text-base ">{modalData?.fullName}</p>

                <p className="text-sm text-gray-700">{modalData?.email}</p>
                <p className="text-sm text-gray-700 my-1">{modalData?.number}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-center gap-2">
            <div>
              <h2 className="my-1 ml-1">Select Employee:</h2>
              <Select
                showSearch
                placeholder="Select an employee"
                value={selectedEmployee}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.label.props.children[0].toLowerCase().includes(input.toLowerCase())
                }
                className="w-[400px]"
                onChange={(e) => setSelectedEmployee(e)}
                options={employees.map((employee) => ({
                  value: employee.id,
                  label: (
                    <>
                      {employee.fullName}{" "}
                      <span className="text-gray-500 text-xs">{employee.email}</span>
                    </>
                  ),
                }))}
              />
            </div>
            <ScheduleList dateAndTime={dateAndTime} />
            <div>
              <h2 className="my-1 ml-1">Select Days & Time:</h2>
              <Select
                mode="tags"
                className="w-[400px]"
                placeholder="Please select days"
                value={curDateTime.days}
                onChange={(e) => setCurDateTime({ ...curDateTime, days: e })}
                options={[
                  { value: "monday" },
                  { value: "tuesday" },
                  { value: "wednesday" },
                  { value: "thursday" },
                  { value: "friday" },
                  { value: "saturday" },
                  { value: "sunday" },
                ]}
              />
            </div>
            <TimePicker.RangePicker
              className="w-[400px]"
              use12Hours
              format="h:mm a"
              value={curDateTime.timeValue}
              minuteStep={10}
              onChange={(e) =>
                setCurDateTime({
                  ...curDateTime,
                  startTime: e[0].$d,
                  endTime: e[1].$d,
                  timeValue: e,
                })
              }
            />
            <div className="flex w-[400px] mx-auto items-center justify-between">
              <div className="flex justify-start items-center">
                {/*     <input
                  type="checkbox"
                  checked={curDateTime.isRecurring}
                  onChange={(e) =>
                    setCurDateTime({
                      ...curDateTime,
                      isRecurring: e.target.checked,
                    })
                  }
                />
                <label htmlFor="" className="ml-2 text-gray-700">
                  Repeats every week
                </label>*/}
              </div>
              <button
                type="button"
                onClick={() => {
                  setDateAndTime([...dateAndTime, curDateTime]);
                  setCurDateTime({
                    days: [],
                    startTime: "",
                    endTime: "",
                    timeValue: null,
                    isRecurring: true,
                  });
                }}
                className={`btn-primary ml-auto mr-0 ${
                  (!curDateTime?.days.length > 0 ||
                    !curDateTime.startTime ||
                    !curDateTime.endTime) &&
                  "opacity-60"
                }`}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="relative flex flex-col w-full gap-2 p-4 md:w-1/2">
        <div className="relative flex w-full ">
          <IoSearch className="text-[18px] text-[#696976] absolute left-2 top-3" />
          <input
            className="pl-8 rounded-[8px] w-full p-2 border outline-none"
            placeholder="Search Client"
            value={searchText} // Controlled input value
            onChange={(e) => onSearch(e.target.value)} // Handle input change
          />
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={filteredData}
        // onRow={}
        style={{ color: "#444454", cursor: "pointer" }}
        pagination={paginationConfig}
      />
    </div>
  );
};

export default ClientData;
