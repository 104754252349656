import authenticatedInstance from "./AxiosInstance/authenticated";

export const getAssetLink = (key) => async (dispatch) => {
  try {
    const { status, data } = await authenticatedInstance.post(
      "/assets/get-asset-file-link",
      {
        key,
      }
    );
    if (data.status) {
      return data.url;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};
