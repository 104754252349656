import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { message } from "antd";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const create = (emp) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post(
      "/employees/create",
      {
        data: emp,
      }
    );
    if (status === 200) {
      dispatch({
        type: "CREATE_EMPLOYEE",
        payload: data.emp,
      });
      message.success(data.message);
      return data.emp;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const update = (emp) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post(
      "/employees/update",
      {
        data: emp,
      }
    );
    if (status === 200) {
      dispatch({
        type: "UPDATE_EMPLOYEE",
        payload: data.emp,
      });
      message.success(data.message);
      return true;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getAll = () => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get(
      "/employees/get-all"
    );
    if (status === 200) {
      dispatch({
        type: "SET_ALL_EMPLOYEES",
        payload: data.employees,
      });
      // message.success(data.message);
      return true;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const assignEmpToClient = (optns) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.post(
      "/employees/assign",
      optns
    );
    if (status === 200) {
      dispatch({
        type: "ASSIGN_EMPLOYEE",
        payload: data.emp,
      });
      message.success(data.message);
      return true;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getEmpCalendar = (employeeId) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get(
      `/employees/get-calendar?employeeId=${employeeId}`
    );
    if (status === 200) {
      let evnt = [];
      for (const item of data.events) {
        evnt.push({
          id: item.id,
          title: item?.title,
          description: item?.description,
          clientId: item.clientId,
          start: new Date(item.start),
          end: new Date(item.end),
          type: item.type,
        });
      }
      // console.log(evnt);
      dispatch({
        type: "SET_SELECTED_EMP_CALENDAR",
        payload: evnt,
      });
      // message.success(data.message);
      return data.clients;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const resetDevice = (employeeId) => async () => {
  try {
    // Make API call to reset the device
    const response = await authenticatedInstance.post(
      `/employees/reset-device`,
      {
        employeeId,
      }
    );

    if (response.status === 200) {
      // Show success message
      message.success("Device reset successfully.");
      return true;
    } else {
      throw new Error("Failed to reset device.");
    }
  } catch (error) {
    console.error("Reset Device Error:", error);
    message.error("Failed to reset the device. Please try again.");
    return false;
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
